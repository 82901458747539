@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer components {

  body {
    @apply text-sm text-slate-700 tracking-tight
  }

  body > * {
    @apply outline-none
  }

  a {
    @apply cursor-pointer text-indigo-500 hover:text-indigo-600 hover:underline
  }

  .msx-btn {
    @apply inline-flex items-center justify-center rounded-md border border-slate-300 bg-white outline-none px-3.5 py-2 gap-2 text-sm font-bold text-gray-600 shadow-sm hover:bg-slate-50 hover:text-gray-800 active:text-gray-500 active:shadow-inner
  }

  .msx-btn.msx-btn-primary {
    @apply bg-indigo-600 text-white border-none hover:bg-indigo-700
  }

  .msx-btn.msx-btn-danger {
    @apply bg-red-500 text-white border-none hover:bg-red-600
  }

  .msx-btn.msx-btn-success {
    @apply bg-teal-500 text-white border-none hover:bg-teal-600
  }

  .msx-btn.msx-btn-cancel {
    @apply bg-transparent text-gray-300 border-none shadow-none hover:bg-slate-200/50 hover:text-gray-400
  }

  .msx-btn.msx-btn-delete {
    @apply bg-white text-red-400 border-none shadow-none hover:bg-red-100 hover:text-red-500
  }

  .msx-btn.msx-btn-loading {
    @apply text-gray-400 before:animate-spin before:w-4 before:h-4 before:mr-1 before:inset-0 before:m-auto before:border-4 before:border-slate-200 before:border-t-slate-400 before:rounded-full
  }

  .msx-btn.msx-btn-loading.msx-btn-primary {
    @apply text-indigo-200 before:border-indigo-200 before:border-t-white
  }

  .msx-btn[disabled] {
    @apply border-none bg-slate-100 text-gray-300
  }

  .msx-close-btn {
    @apply cursor-pointer flex-none shrink-0 grow-0 w-5 h-5 relative flex items-center justify-center rounded-full hover:bg-slate-100 hover:ring-8 hover:ring-slate-100 before:absolute before:content-[""] before:w-0.5 before:h-5 before:rounded before:bg-slate-300 before:rotate-45 after:absolute after:content-[""] after:w-0.5 after:h-5 after:rounded after:bg-slate-300 after:rotate-[315deg] hover:before:bg-slate-400 hover:after:bg-slate-400
  }

  .msx-text-btn {
    @apply font-medium text-indigo-600 hover:text-indigo-700 hover:underline
  }
  
  .msx-input,
  .msx-select {
    @apply relative block w-full text-sm font-normal text-gray-700 placeholder-slate-300 bg-white border border-slate-300 rounded-md shadow-sm px-3 py-2.5 m-0 outline-none focus:text-gray-900 hover:border-indigo-400 active:border-indigo-400 focus:border-indigo-400 focus:ring-4 focus:ring-indigo-50
  }

  .msx-select {
    @apply cursor-pointer text-left pr-5
  }

  .msx-input[disabled],
  .msx-select[disabled] {
    @apply text-gray-400 bg-slate-50 border-slate-300 shadow-none
  }

  .msx-input-label {
    @apply block text-gray-600 space-x-2 font-medium text-sm
  }

  .msx-input-label.msx-input-label-required {
    @apply after:content-["*"] after:text-red-600 after:ml-1
  }

  .msx-checkbox {
    @apply cursor-pointer w-4 h-4 align-middle
  }

  .msx-toggle-switch-bar {
    @apply cursor-pointer relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none bg-indigo-600 hover:ring-2 hover:ring-offset-1 hover:ring-indigo-200
  }

  .msx-toggle-switch-bar.msx-toggle-off,
  .msx-toggle-switch-bar[aria-checked="false"] {
    @apply bg-slate-300 hover:ring-slate-200
  }

  .msx-toggle-switch-bar .msx-toggle-switch-btn {
    @apply inline-block h-4 w-4 transform rounded-full bg-white transition-transform translate-x-6
  }

  .msx-toggle-switch-bar.msx-toggle-off .msx-toggle-switch-btn,
  .msx-toggle-switch-bar[aria-checked="false"] .msx-toggle-switch-btn {
    @apply translate-x-1
  }

  .msx-toggle-switch-bar[disabled] {
    @apply bg-indigo-200 hover:ring-0
  }

  .msx-toggle-switch-bar[disabled].msx-toggle-off,
  .msx-toggle-switch-bar[disabled][aria-checked="false"] {
    @apply bg-indigo-200
  }

  .msx-loader {
    @apply inline-block animate-spin w-5 h-5 border-4 border-slate-200 border-t-slate-400 rounded-full
  }

  .msx-table {
    @apply table-auto w-full divide-y divide-slate-200
  }

  .msx-table thead tr th {
    @apply py-2.5 px-2 text-left text-sm font-medium text-gray-400 whitespace-nowrap
  }

  .msx-table tbody tr {
    @apply bg-white hover:bg-slate-50
  }

  .msx-table tbody tr td {
    @apply py-5 px-4 text-left align-middle text-base font-light text-gray-600
  }

  .msx-table tbody {
    @apply divide-y divide-slate-200
  }

  .msx-dropdown-menu {
    @apply bg-white shadow-lg rounded-md py-1 text-base border border-slate-300/75 overflow-auto focus:outline-none sm:text-sm
  }

  .msx-dropdown-menu .msx-dropdown-menu-item {
    @apply relative cursor-pointer block select-none py-2 px-3 text-sm font-normal text-slate-600 hover:no-underline hover:bg-slate-100 hover:text-slate-800
  }

  .msx-dropdown-menu .msx-dropdown-menu-item[aria-selected="true"],
  .msx-dropdown-menu .msx-dropdown-menu-item.msx-dropdown-menu-item-selected {
    @apply bg-blue-100 text-slate-800 pr-6 before:absolute before:inset-y-0 before:right-0 before:content-["✔︎"] before:flex before:items-center before:pr-2 before:text-green-600 before:text-lg
  }

  .msx-dropdown-menu .msx-dropdown-menu-item .msx-dropdown-menu-subtext {
    @apply mt-1 font-light text-slate-400
  }

  .msx-modal-backdrop {
    @apply fixed top-0 right-0 left-0 z-50 w-full h-screen bg-gray-700 bg-opacity-50 flex justify-center items-center gap-4 p-6 md:h-full
  }

  .msx-modal {
    @apply flex flex-col w-full max-w-5xl h-fit max-h-full bg-white rounded-lg shadow
  }

  .msx-alert-modal {
    @apply max-w-xl
  }

  .msx-form-modal {
    @apply h-full max-w-5xl max-h-[54rem] m-0
  }

  .msx-setting-panel-modal {
    @apply h-full max-w-6xl max-h-[60rem] m-0
  }

  .msx-modal-header {
    @apply flex w-full items-center justify-between bg-white px-5 py-3 border-b border-b-slate-200 rounded-t-lg
  }

  .msx-modal-header-left {
    @apply grow justify-start
  }

  .msx-modal-header-right {
    @apply grow-0 justify-end
  }

  .msx-modal-header-title {
    @apply text-base font-bold text-slate-800
  }

  .msx-modal-header-subtext {
    @apply text-sm text-slate-400 mt-2
  }

  .msx-modal-content {
    @apply relative flex-grow bg-white overflow-y-scroll px-8 py-6
  }

  .msx-modal-footer {
    @apply flex w-full items-center justify-between px-3 py-2 bg-white py-3 px-4 border-t border-t-slate-200 rounded-b-lg
  }

  .msx-tabs {
    @apply flex justify-start items-end w-full bg-transparent border-b border-b-slate-200 py-0 px-2 space-x-2
  }

  .msx-tabs .msx-tab {
    @apply cursor-pointer text-slate-400 font-medium border-b-2 border-b-transparent whitespace-nowrap no-underline px-3 py-4 mx-0.5 my-0 hover:bg-slate-100 hover:text-slate-700
  }

  .msx-tabs .msx-tab[aria-selected="true"],
  .msx-tabs .msx-tab.msx-tab-selected {
    @apply text-indigo-600 border-b-indigo-500 hover:bg-indigo-50 hover:text-indigo-700
  }

  .msx-setting-rows {
    @apply w-full
  }

  .msx-setting-rows .msx-setting-row {
    @apply flex flex-row items-center justify-between px-4 py-4 border-b border-b-slate-200
  }

  .msx-setting-rows .msx-setting-row .msx-setting-row-items {
    @apply flex flex-row items-center
  }

  .msx-setting-rows .msx-setting-row .msx-setting-row-items .msx-setting-row-item {
    @apply before:content-["\b7"] before:mx-2 first:before:content-none first:before:mx-0
  }

  .msx-alert {
    @apply p-4 rounded text-sm text-slate-500 border border-slate-200 bg-slate-200/75
  }

  .msx-alert.msx-alert-primary {
    @apply text-blue-700 border-blue-100 bg-blue-100/75
  }

  .msx-alert.msx-alert-success {
    @apply text-green-700 border-green-100 bg-green-100/75
  }

  .msx-alert.msx-alert-error {
    @apply text-red-700 border-red-100 bg-red-100/75
  }

  .msx-alert.msx-alert-warning {
    @apply text-yellow-700 border-yellow-100 bg-yellow-100/75
  }

  .msx-tag {
    @apply rounded-full text-sm px-2.5 py-1 relative inline-block font-medium hover:no-underline hover:text-inherit
  }

  .msx-tag.msx-tag-success {
    @apply bg-green-500 text-white
  }

  .msx-tag.msx-tag-inactive {
    @apply bg-slate-500 text-white 
  }

}

/* Down-arrow for select box */

.msx-select {
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 4.5px 4.5px, 4.5px 4.5px;
  background-image: linear-gradient(45deg, transparent 50%, rgba(0,0,0,0.5) 50%), linear-gradient(135deg, rgba(0,0,0,0.5) 50%, transparent 50%);
  background-position: calc(100% - 10px - 4.5px) center, calc(100% - 10px) center;
  background-repeat: no-repeat;
  padding-right: 25px;
}
